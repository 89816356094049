// Generated from /DSL/DSL.Library/graph.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';


const serializedATN = [4,0,110,950,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,
2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,
7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,
19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,
2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,
34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,
7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,
48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,55,7,55,
2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,61,2,62,7,62,2,
63,7,63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,
7,70,2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,
77,2,78,7,78,2,79,7,79,2,80,7,80,2,81,7,81,2,82,7,82,2,83,7,83,2,84,7,84,
2,85,7,85,2,86,7,86,2,87,7,87,2,88,7,88,2,89,7,89,2,90,7,90,2,91,7,91,2,
92,7,92,2,93,7,93,2,94,7,94,2,95,7,95,2,96,7,96,2,97,7,97,2,98,7,98,2,99,
7,99,2,100,7,100,2,101,7,101,2,102,7,102,2,103,7,103,2,104,7,104,2,105,7,
105,2,106,7,106,2,107,7,107,2,108,7,108,2,109,7,109,2,110,7,110,2,111,7,
111,2,112,7,112,2,113,7,113,2,114,7,114,2,115,7,115,2,116,7,116,2,117,7,
117,2,118,7,118,2,119,7,119,2,120,7,120,1,0,1,0,1,0,1,0,1,0,1,1,1,1,1,2,
1,2,5,2,253,8,2,10,2,12,2,256,9,2,1,2,1,2,1,2,1,2,5,2,262,8,2,10,2,12,2,
265,9,2,1,2,1,2,3,2,269,8,2,1,3,1,3,3,3,273,8,3,1,4,1,4,3,4,277,8,4,1,5,
1,5,1,5,1,6,1,6,1,6,1,7,1,7,1,8,1,8,1,9,1,9,1,10,1,10,1,11,1,11,1,12,1,12,
1,12,1,12,1,12,1,12,1,13,1,13,1,13,1,13,1,13,1,14,1,14,1,14,1,15,1,15,1,
15,1,15,1,15,1,15,1,16,1,16,1,16,1,16,1,16,1,16,1,17,1,17,1,17,1,17,1,17,
1,17,1,17,1,17,1,17,1,17,1,18,1,18,1,18,1,18,1,18,1,18,1,19,1,19,1,19,1,
19,1,19,1,19,1,19,1,19,1,20,1,20,1,20,1,20,1,20,1,20,1,20,1,20,1,20,1,20,
1,20,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,22,1,22,1,22,1,
22,1,22,1,22,1,22,1,22,1,23,1,23,1,23,1,23,1,24,1,24,1,24,1,24,1,24,1,24,
1,24,1,24,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,26,1,26,1,26,1,26,1,
26,1,26,1,26,1,26,1,26,1,26,1,26,1,27,1,27,1,27,1,27,1,27,1,27,1,27,1,27,
1,27,1,27,1,27,1,27,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,29,1,29,1,
29,1,30,1,30,1,30,1,30,1,30,1,31,1,31,1,31,1,31,1,31,1,32,1,32,1,32,1,32,
1,32,1,32,1,32,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,34,1,34,1,
34,1,34,1,34,1,35,1,35,1,35,1,35,1,35,1,35,1,35,1,35,1,36,1,36,1,36,1,36,
1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,38,1,38,1,38,1,38,1,38,1,
38,1,38,1,39,1,39,1,39,1,39,1,39,1,40,1,40,1,40,1,41,1,41,1,41,1,41,1,41,
1,41,1,41,1,42,1,42,1,42,1,43,1,43,1,43,1,43,1,43,1,43,1,44,1,44,1,44,1,
45,1,45,1,45,1,45,1,45,1,45,1,45,1,45,1,46,1,46,1,46,1,46,1,46,1,46,1,46,
1,46,1,47,1,47,1,47,1,47,1,47,1,48,1,48,1,48,1,48,1,49,1,49,1,49,1,50,1,
50,1,50,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,52,
1,52,1,52,1,52,1,52,1,52,1,52,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,54,1,
54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,55,1,55,1,55,
1,55,1,55,1,55,1,55,1,55,1,55,1,56,1,56,1,56,1,56,1,56,1,56,1,56,1,57,1,
57,1,57,1,57,1,57,1,58,1,58,1,58,1,58,1,59,1,59,1,59,1,59,1,59,1,59,1,59,
1,60,1,60,1,60,1,60,1,60,1,60,1,61,1,61,1,61,1,61,1,61,1,61,1,61,1,62,1,
62,1,62,1,62,1,62,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,64,1,64,1,64,1,64,
1,64,1,64,1,64,1,64,1,65,1,65,1,65,1,65,1,65,1,65,1,65,1,65,1,65,1,65,1,
65,1,65,1,66,1,66,1,66,1,66,1,66,1,67,1,67,1,67,1,67,1,68,1,68,1,68,1,68,
1,68,1,69,1,69,1,69,1,69,1,70,1,70,1,70,1,70,1,70,1,71,1,71,1,71,1,71,1,
71,1,72,1,72,1,72,1,72,1,72,1,73,1,73,1,74,1,74,1,75,3,75,699,8,75,1,75,
1,75,1,76,3,76,704,8,76,1,76,1,76,1,77,1,77,1,77,5,77,711,8,77,10,77,12,
77,714,9,77,1,77,3,77,717,8,77,1,77,1,77,4,77,721,8,77,11,77,12,77,722,1,
77,3,77,726,8,77,1,77,1,77,1,77,3,77,731,8,77,1,78,1,78,1,78,5,78,736,8,
78,10,78,12,78,739,9,78,3,78,741,8,78,1,79,1,79,3,79,745,8,79,1,79,4,79,
748,8,79,11,79,12,79,749,1,80,1,80,1,80,1,80,1,80,1,80,1,80,1,80,1,80,3,
80,761,8,80,1,81,1,81,1,81,1,81,1,81,1,81,1,82,1,82,1,82,1,82,1,82,1,82,
1,82,1,83,1,83,1,83,1,83,1,83,1,83,1,83,1,83,1,84,1,84,1,84,1,84,1,84,1,
84,1,84,1,85,1,85,1,85,1,85,1,85,1,85,1,85,1,85,1,86,1,86,1,86,1,86,1,86,
1,87,1,87,1,88,1,88,1,89,1,89,1,90,1,90,1,91,1,91,1,92,1,92,1,93,1,93,1,
94,1,94,1,95,1,95,1,96,1,96,1,97,1,97,1,98,1,98,1,99,1,99,1,100,1,100,1,
101,1,101,1,102,1,102,1,103,1,103,1,103,1,104,1,104,1,104,1,105,1,105,1,
105,1,106,1,106,1,106,1,107,1,107,1,107,1,108,1,108,1,108,1,109,1,109,1,
109,1,109,3,109,858,8,109,1,110,1,110,1,111,1,111,1,111,1,111,1,111,3,111,
867,8,111,1,112,1,112,3,112,871,8,112,1,113,3,113,874,8,113,1,113,1,113,
5,113,878,8,113,10,113,12,113,881,9,113,1,113,3,113,884,8,113,1,113,1,113,
1,113,5,113,889,8,113,10,113,12,113,892,9,113,3,113,894,8,113,1,114,1,114,
1,115,1,115,1,116,1,116,3,116,902,8,116,1,117,1,117,1,117,1,117,5,117,908,
8,117,10,117,12,117,911,9,117,1,117,1,117,1,118,1,118,1,118,1,118,1,118,
5,118,920,8,118,10,118,12,118,923,9,118,1,118,1,118,1,118,1,118,1,118,1,
119,1,119,1,119,1,119,5,119,934,8,119,10,119,12,119,937,9,119,1,119,1,119,
1,119,1,119,1,119,1,120,4,120,945,8,120,11,120,12,120,946,1,120,1,120,2,
921,935,0,121,1,1,3,0,5,2,7,0,9,0,11,0,13,0,15,3,17,4,19,5,21,6,23,7,25,
8,27,9,29,10,31,11,33,12,35,13,37,14,39,15,41,16,43,17,45,18,47,19,49,20,
51,21,53,22,55,23,57,24,59,25,61,26,63,27,65,28,67,29,69,30,71,31,73,32,
75,33,77,34,79,35,81,36,83,37,85,38,87,39,89,40,91,41,93,42,95,43,97,44,
99,45,101,46,103,47,105,48,107,49,109,50,111,51,113,52,115,53,117,54,119,
55,121,56,123,57,125,58,127,59,129,60,131,61,133,62,135,63,137,64,139,65,
141,66,143,67,145,68,147,69,149,70,151,71,153,72,155,0,157,0,159,0,161,73,
163,74,165,75,167,76,169,77,171,78,173,79,175,80,177,81,179,82,181,83,183,
84,185,85,187,86,189,87,191,88,193,89,195,90,197,91,199,92,201,93,203,94,
205,95,207,96,209,97,211,98,213,99,215,100,217,101,219,102,221,103,223,104,
225,105,227,106,229,0,231,0,233,0,235,107,237,108,239,109,241,110,1,0,10,
3,0,0,31,34,34,92,92,1,0,96,96,8,0,34,34,39,39,92,92,98,98,102,102,110,110,
114,114,116,116,1,0,48,57,1,0,49,57,2,0,69,69,101,101,2,0,43,43,45,45,3,
0,48,57,65,90,97,122,2,0,10,10,13,13,3,0,9,10,13,13,32,32,969,0,1,1,0,0,
0,0,5,1,0,0,0,0,15,1,0,0,0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,0,23,1,
0,0,0,0,25,1,0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,0,0,0,0,
35,1,0,0,0,0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,0,0,0,45,1,0,
0,0,0,47,1,0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,0,53,1,0,0,0,0,55,1,0,0,0,0,57,
1,0,0,0,0,59,1,0,0,0,0,61,1,0,0,0,0,63,1,0,0,0,0,65,1,0,0,0,0,67,1,0,0,0,
0,69,1,0,0,0,0,71,1,0,0,0,0,73,1,0,0,0,0,75,1,0,0,0,0,77,1,0,0,0,0,79,1,
0,0,0,0,81,1,0,0,0,0,83,1,0,0,0,0,85,1,0,0,0,0,87,1,0,0,0,0,89,1,0,0,0,0,
91,1,0,0,0,0,93,1,0,0,0,0,95,1,0,0,0,0,97,1,0,0,0,0,99,1,0,0,0,0,101,1,0,
0,0,0,103,1,0,0,0,0,105,1,0,0,0,0,107,1,0,0,0,0,109,1,0,0,0,0,111,1,0,0,
0,0,113,1,0,0,0,0,115,1,0,0,0,0,117,1,0,0,0,0,119,1,0,0,0,0,121,1,0,0,0,
0,123,1,0,0,0,0,125,1,0,0,0,0,127,1,0,0,0,0,129,1,0,0,0,0,131,1,0,0,0,0,
133,1,0,0,0,0,135,1,0,0,0,0,137,1,0,0,0,0,139,1,0,0,0,0,141,1,0,0,0,0,143,
1,0,0,0,0,145,1,0,0,0,0,147,1,0,0,0,0,149,1,0,0,0,0,151,1,0,0,0,0,153,1,
0,0,0,0,161,1,0,0,0,0,163,1,0,0,0,0,165,1,0,0,0,0,167,1,0,0,0,0,169,1,0,
0,0,0,171,1,0,0,0,0,173,1,0,0,0,0,175,1,0,0,0,0,177,1,0,0,0,0,179,1,0,0,
0,0,181,1,0,0,0,0,183,1,0,0,0,0,185,1,0,0,0,0,187,1,0,0,0,0,189,1,0,0,0,
0,191,1,0,0,0,0,193,1,0,0,0,0,195,1,0,0,0,0,197,1,0,0,0,0,199,1,0,0,0,0,
201,1,0,0,0,0,203,1,0,0,0,0,205,1,0,0,0,0,207,1,0,0,0,0,209,1,0,0,0,0,211,
1,0,0,0,0,213,1,0,0,0,0,215,1,0,0,0,0,217,1,0,0,0,0,219,1,0,0,0,0,221,1,
0,0,0,0,223,1,0,0,0,0,225,1,0,0,0,0,227,1,0,0,0,0,235,1,0,0,0,0,237,1,0,
0,0,0,239,1,0,0,0,0,241,1,0,0,0,1,243,1,0,0,0,3,248,1,0,0,0,5,268,1,0,0,
0,7,272,1,0,0,0,9,276,1,0,0,0,11,278,1,0,0,0,13,281,1,0,0,0,15,284,1,0,0,
0,17,286,1,0,0,0,19,288,1,0,0,0,21,290,1,0,0,0,23,292,1,0,0,0,25,294,1,0,
0,0,27,300,1,0,0,0,29,305,1,0,0,0,31,308,1,0,0,0,33,314,1,0,0,0,35,320,1,
0,0,0,37,330,1,0,0,0,39,336,1,0,0,0,41,344,1,0,0,0,43,355,1,0,0,0,45,365,
1,0,0,0,47,373,1,0,0,0,49,377,1,0,0,0,51,385,1,0,0,0,53,393,1,0,0,0,55,404,
1,0,0,0,57,416,1,0,0,0,59,424,1,0,0,0,61,427,1,0,0,0,63,432,1,0,0,0,65,437,
1,0,0,0,67,444,1,0,0,0,69,453,1,0,0,0,71,458,1,0,0,0,73,466,1,0,0,0,75,470,
1,0,0,0,77,479,1,0,0,0,79,486,1,0,0,0,81,491,1,0,0,0,83,494,1,0,0,0,85,501,
1,0,0,0,87,504,1,0,0,0,89,510,1,0,0,0,91,513,1,0,0,0,93,521,1,0,0,0,95,529,
1,0,0,0,97,534,1,0,0,0,99,538,1,0,0,0,101,541,1,0,0,0,103,544,1,0,0,0,105,
556,1,0,0,0,107,563,1,0,0,0,109,570,1,0,0,0,111,583,1,0,0,0,113,592,1,0,
0,0,115,599,1,0,0,0,117,604,1,0,0,0,119,608,1,0,0,0,121,615,1,0,0,0,123,
621,1,0,0,0,125,628,1,0,0,0,127,633,1,0,0,0,129,640,1,0,0,0,131,648,1,0,
0,0,133,660,1,0,0,0,135,665,1,0,0,0,137,669,1,0,0,0,139,674,1,0,0,0,141,
678,1,0,0,0,143,683,1,0,0,0,145,688,1,0,0,0,147,693,1,0,0,0,149,695,1,0,
0,0,151,698,1,0,0,0,153,703,1,0,0,0,155,730,1,0,0,0,157,740,1,0,0,0,159,
742,1,0,0,0,161,760,1,0,0,0,163,762,1,0,0,0,165,768,1,0,0,0,167,775,1,0,
0,0,169,783,1,0,0,0,171,790,1,0,0,0,173,798,1,0,0,0,175,803,1,0,0,0,177,
805,1,0,0,0,179,807,1,0,0,0,181,809,1,0,0,0,183,811,1,0,0,0,185,813,1,0,
0,0,187,815,1,0,0,0,189,817,1,0,0,0,191,819,1,0,0,0,193,821,1,0,0,0,195,
823,1,0,0,0,197,825,1,0,0,0,199,827,1,0,0,0,201,829,1,0,0,0,203,831,1,0,
0,0,205,833,1,0,0,0,207,835,1,0,0,0,209,838,1,0,0,0,211,841,1,0,0,0,213,
844,1,0,0,0,215,847,1,0,0,0,217,850,1,0,0,0,219,857,1,0,0,0,221,859,1,0,
0,0,223,866,1,0,0,0,225,870,1,0,0,0,227,893,1,0,0,0,229,895,1,0,0,0,231,
897,1,0,0,0,233,901,1,0,0,0,235,903,1,0,0,0,237,914,1,0,0,0,239,929,1,0,
0,0,241,944,1,0,0,0,243,244,5,102,0,0,244,245,5,114,0,0,245,246,5,111,0,
0,246,247,5,109,0,0,247,2,1,0,0,0,248,249,8,0,0,0,249,4,1,0,0,0,250,254,
3,193,96,0,251,253,3,9,4,0,252,251,1,0,0,0,253,256,1,0,0,0,254,252,1,0,0,
0,254,255,1,0,0,0,255,257,1,0,0,0,256,254,1,0,0,0,257,258,3,193,96,0,258,
269,1,0,0,0,259,263,3,191,95,0,260,262,3,7,3,0,261,260,1,0,0,0,262,265,1,
0,0,0,263,261,1,0,0,0,263,264,1,0,0,0,264,266,1,0,0,0,265,263,1,0,0,0,266,
267,3,191,95,0,267,269,1,0,0,0,268,250,1,0,0,0,268,259,1,0,0,0,269,6,1,0,
0,0,270,273,3,3,1,0,271,273,3,13,6,0,272,270,1,0,0,0,272,271,1,0,0,0,273,
8,1,0,0,0,274,277,8,1,0,0,275,277,3,11,5,0,276,274,1,0,0,0,276,275,1,0,0,
0,277,10,1,0,0,0,278,279,5,92,0,0,279,280,7,1,0,0,280,12,1,0,0,0,281,282,
5,92,0,0,282,283,7,2,0,0,283,14,1,0,0,0,284,285,5,43,0,0,285,16,1,0,0,0,
286,287,5,45,0,0,287,18,1,0,0,0,288,289,5,42,0,0,289,20,1,0,0,0,290,291,
5,47,0,0,291,22,1,0,0,0,292,293,5,37,0,0,293,24,1,0,0,0,294,295,5,97,0,0,
295,296,5,112,0,0,296,297,5,112,0,0,297,298,5,108,0,0,298,299,5,121,0,0,
299,26,1,0,0,0,300,301,5,119,0,0,301,302,5,105,0,0,302,303,5,116,0,0,303,
304,5,104,0,0,304,28,1,0,0,0,305,306,5,97,0,0,306,307,5,115,0,0,307,30,1,
0,0,0,308,309,5,97,0,0,309,310,5,115,0,0,310,311,5,121,0,0,311,312,5,110,
0,0,312,313,5,99,0,0,313,32,1,0,0,0,314,315,5,98,0,0,315,316,5,108,0,0,316,
317,5,111,0,0,317,318,5,99,0,0,318,319,5,107,0,0,319,34,1,0,0,0,320,321,
5,98,0,0,321,322,5,108,0,0,322,323,5,111,0,0,323,324,5,99,0,0,324,325,5,
107,0,0,325,326,5,99,0,0,326,327,5,97,0,0,327,328,5,108,0,0,328,329,5,108,
0,0,329,36,1,0,0,0,330,331,5,99,0,0,331,332,5,97,0,0,332,333,5,116,0,0,333,
334,5,99,0,0,334,335,5,104,0,0,335,38,1,0,0,0,336,337,5,99,0,0,337,338,5,
111,0,0,338,339,5,109,0,0,339,340,5,109,0,0,340,341,5,101,0,0,341,342,5,
110,0,0,342,343,5,116,0,0,343,40,1,0,0,0,344,345,5,99,0,0,345,346,5,111,
0,0,346,347,5,110,0,0,347,348,5,100,0,0,348,349,5,105,0,0,349,350,5,116,
0,0,350,351,5,105,0,0,351,352,5,111,0,0,352,353,5,110,0,0,353,354,5,115,
0,0,354,42,1,0,0,0,355,356,5,99,0,0,356,357,5,111,0,0,357,358,5,110,0,0,
358,359,5,102,0,0,359,360,5,105,0,0,360,361,5,100,0,0,361,362,5,101,0,0,
362,363,5,110,0,0,363,364,5,116,0,0,364,44,1,0,0,0,365,366,5,99,0,0,366,
367,5,111,0,0,367,368,5,110,0,0,368,369,5,116,0,0,369,370,5,101,0,0,370,
371,5,120,0,0,371,372,5,116,0,0,372,46,1,0,0,0,373,374,5,99,0,0,374,375,
5,111,0,0,375,376,5,119,0,0,376,48,1,0,0,0,377,378,5,100,0,0,378,379,5,101,
0,0,379,380,5,102,0,0,380,381,5,97,0,0,381,382,5,117,0,0,382,383,5,108,0,
0,383,384,5,116,0,0,384,50,1,0,0,0,385,386,5,100,0,0,386,387,5,101,0,0,387,
388,5,102,0,0,388,389,5,105,0,0,389,390,5,110,0,0,390,391,5,101,0,0,391,
392,5,100,0,0,392,52,1,0,0,0,393,394,5,100,0,0,394,395,5,105,0,0,395,396,
5,103,0,0,396,397,5,114,0,0,397,398,5,101,0,0,398,399,5,115,0,0,399,400,
5,115,0,0,400,401,5,105,0,0,401,402,5,111,0,0,402,403,5,110,0,0,403,54,1,
0,0,0,404,405,5,100,0,0,405,406,5,105,0,0,406,407,5,103,0,0,407,408,5,114,
0,0,408,409,5,101,0,0,409,410,5,115,0,0,410,411,5,115,0,0,411,412,5,105,
0,0,412,413,5,111,0,0,413,414,5,110,0,0,414,415,5,115,0,0,415,56,1,0,0,0,
416,417,5,100,0,0,417,418,5,105,0,0,418,419,5,115,0,0,419,420,5,97,0,0,420,
421,5,98,0,0,421,422,5,108,0,0,422,423,5,101,0,0,423,58,1,0,0,0,424,425,
5,100,0,0,425,426,5,111,0,0,426,60,1,0,0,0,427,428,5,100,0,0,428,429,5,111,
0,0,429,430,5,110,0,0,430,431,5,101,0,0,431,62,1,0,0,0,432,433,5,101,0,0,
433,434,5,108,0,0,434,435,5,115,0,0,435,436,5,101,0,0,436,64,1,0,0,0,437,
438,5,101,0,0,438,439,5,110,0,0,439,440,5,97,0,0,440,441,5,98,0,0,441,442,
5,108,0,0,442,443,5,101,0,0,443,66,1,0,0,0,444,445,5,101,0,0,445,446,5,120,
0,0,446,447,5,116,0,0,447,448,5,101,0,0,448,449,5,114,0,0,449,450,5,110,
0,0,450,451,5,97,0,0,451,452,5,108,0,0,452,68,1,0,0,0,453,454,5,101,0,0,
454,455,5,120,0,0,455,456,5,105,0,0,456,457,5,116,0,0,457,70,1,0,0,0,458,
459,5,102,0,0,459,460,5,105,0,0,460,461,5,110,0,0,461,462,5,97,0,0,462,463,
5,108,0,0,463,464,5,108,0,0,464,465,5,121,0,0,465,72,1,0,0,0,466,467,5,102,
0,0,467,468,5,111,0,0,468,469,5,114,0,0,469,74,1,0,0,0,470,471,5,102,0,0,
471,472,5,117,0,0,472,473,5,110,0,0,473,474,5,99,0,0,474,475,5,116,0,0,475,
476,5,105,0,0,476,477,5,111,0,0,477,478,5,110,0,0,478,76,1,0,0,0,479,480,
5,103,0,0,480,481,5,108,0,0,481,482,5,111,0,0,482,483,5,98,0,0,483,484,5,
97,0,0,484,485,5,108,0,0,485,78,1,0,0,0,486,487,5,103,0,0,487,488,5,111,
0,0,488,489,5,116,0,0,489,490,5,111,0,0,490,80,1,0,0,0,491,492,5,105,0,0,
492,493,5,102,0,0,493,82,1,0,0,0,494,495,5,105,0,0,495,496,5,109,0,0,496,
497,5,112,0,0,497,498,5,111,0,0,498,499,5,114,0,0,499,500,5,116,0,0,500,
84,1,0,0,0,501,502,5,105,0,0,502,503,5,110,0,0,503,86,1,0,0,0,504,505,5,
105,0,0,505,506,5,110,0,0,506,507,5,112,0,0,507,508,5,117,0,0,508,509,5,
116,0,0,509,88,1,0,0,0,510,511,5,105,0,0,511,512,5,115,0,0,512,90,1,0,0,
0,513,514,5,108,0,0,514,515,5,105,0,0,515,516,5,98,0,0,516,517,5,114,0,0,
517,518,5,97,0,0,518,519,5,114,0,0,519,520,5,121,0,0,520,92,1,0,0,0,521,
522,5,108,0,0,522,523,5,111,0,0,523,524,5,103,0,0,524,525,5,101,0,0,525,
526,5,120,0,0,526,527,5,112,0,0,527,528,5,114,0,0,528,94,1,0,0,0,529,530,
5,110,0,0,530,531,5,111,0,0,531,532,5,100,0,0,532,533,5,101,0,0,533,96,1,
0,0,0,534,535,5,110,0,0,535,536,5,111,0,0,536,537,5,116,0,0,537,98,1,0,0,
0,538,539,5,111,0,0,539,540,5,102,0,0,540,100,1,0,0,0,541,542,5,111,0,0,
542,543,5,110,0,0,543,102,1,0,0,0,544,545,5,111,0,0,545,546,5,110,0,0,546,
547,5,100,0,0,547,548,5,105,0,0,548,549,5,103,0,0,549,550,5,114,0,0,550,
551,5,101,0,0,551,552,5,116,0,0,552,553,5,117,0,0,553,554,5,114,0,0,554,
555,5,110,0,0,555,104,1,0,0,0,556,557,5,111,0,0,557,558,5,110,0,0,558,559,
5,101,0,0,559,560,5,120,0,0,560,561,5,105,0,0,561,562,5,116,0,0,562,106,
1,0,0,0,563,564,5,111,0,0,564,565,5,117,0,0,565,566,5,116,0,0,566,567,5,
112,0,0,567,568,5,117,0,0,568,569,5,116,0,0,569,108,1,0,0,0,570,571,5,112,
0,0,571,572,5,114,0,0,572,573,5,101,0,0,573,574,5,112,0,0,574,575,5,114,
0,0,575,576,5,111,0,0,576,577,5,99,0,0,577,578,5,101,0,0,578,579,5,115,0,
0,579,580,5,115,0,0,580,581,5,111,0,0,581,582,5,114,0,0,582,110,1,0,0,0,
583,584,5,112,0,0,584,585,5,114,0,0,585,586,5,105,0,0,586,587,5,111,0,0,
587,588,5,114,0,0,588,589,5,105,0,0,589,590,5,116,0,0,590,591,5,121,0,0,
591,112,1,0,0,0,592,593,5,114,0,0,593,594,5,101,0,0,594,595,5,116,0,0,595,
596,5,117,0,0,596,597,5,114,0,0,597,598,5,110,0,0,598,114,1,0,0,0,599,600,
5,115,0,0,600,601,5,97,0,0,601,602,5,118,0,0,602,603,5,101,0,0,603,116,1,
0,0,0,604,605,5,115,0,0,605,606,5,101,0,0,606,607,5,116,0,0,607,118,1,0,
0,0,608,609,5,115,0,0,609,610,5,104,0,0,610,611,5,97,0,0,611,612,5,114,0,
0,612,613,5,101,0,0,613,614,5,100,0,0,614,120,1,0,0,0,615,616,5,115,0,0,
616,617,5,116,0,0,617,618,5,97,0,0,618,619,5,114,0,0,619,620,5,116,0,0,620,
122,1,0,0,0,621,622,5,115,0,0,622,623,5,116,0,0,623,624,5,97,0,0,624,625,
5,116,0,0,625,626,5,105,0,0,626,627,5,99,0,0,627,124,1,0,0,0,628,629,5,115,
0,0,629,630,5,121,0,0,630,631,5,110,0,0,631,632,5,99,0,0,632,126,1,0,0,0,
633,634,5,115,0,0,634,635,5,121,0,0,635,636,5,115,0,0,636,637,5,116,0,0,
637,638,5,101,0,0,638,639,5,109,0,0,639,128,1,0,0,0,640,641,5,116,0,0,641,
642,5,105,0,0,642,643,5,109,0,0,643,644,5,101,0,0,644,645,5,111,0,0,645,
646,5,117,0,0,646,647,5,116,0,0,647,130,1,0,0,0,648,649,5,116,0,0,649,650,
5,114,0,0,650,651,5,97,0,0,651,652,5,110,0,0,652,653,5,115,0,0,653,654,5,
105,0,0,654,655,5,116,0,0,655,656,5,105,0,0,656,657,5,111,0,0,657,658,5,
110,0,0,658,659,5,115,0,0,659,132,1,0,0,0,660,661,5,116,0,0,661,662,5,97,
0,0,662,663,5,103,0,0,663,664,5,115,0,0,664,134,1,0,0,0,665,666,5,116,0,
0,666,667,5,114,0,0,667,668,5,121,0,0,668,136,1,0,0,0,669,670,5,116,0,0,
670,671,5,121,0,0,671,672,5,112,0,0,672,673,5,101,0,0,673,138,1,0,0,0,674,
675,5,118,0,0,675,676,5,97,0,0,676,677,5,114,0,0,677,140,1,0,0,0,678,679,
5,119,0,0,679,680,5,97,0,0,680,681,5,105,0,0,681,682,5,116,0,0,682,142,1,
0,0,0,683,684,5,119,0,0,684,685,5,104,0,0,685,686,5,101,0,0,686,687,5,110,
0,0,687,144,1,0,0,0,688,689,5,102,0,0,689,690,5,105,0,0,690,691,5,108,0,
0,691,692,5,108,0,0,692,146,1,0,0,0,693,694,5,35,0,0,694,148,1,0,0,0,695,
696,5,36,0,0,696,150,1,0,0,0,697,699,3,17,8,0,698,697,1,0,0,0,698,699,1,
0,0,0,699,700,1,0,0,0,700,701,3,155,77,0,701,152,1,0,0,0,702,704,3,17,8,
0,703,702,1,0,0,0,703,704,1,0,0,0,704,705,1,0,0,0,705,706,3,157,78,0,706,
154,1,0,0,0,707,708,3,157,78,0,708,712,3,203,101,0,709,711,7,3,0,0,710,709,
1,0,0,0,711,714,1,0,0,0,712,710,1,0,0,0,712,713,1,0,0,0,713,716,1,0,0,0,
714,712,1,0,0,0,715,717,3,159,79,0,716,715,1,0,0,0,716,717,1,0,0,0,717,731,
1,0,0,0,718,720,3,203,101,0,719,721,7,3,0,0,720,719,1,0,0,0,721,722,1,0,
0,0,722,720,1,0,0,0,722,723,1,0,0,0,723,725,1,0,0,0,724,726,3,159,79,0,725,
724,1,0,0,0,725,726,1,0,0,0,726,731,1,0,0,0,727,728,3,157,78,0,728,729,3,
159,79,0,729,731,1,0,0,0,730,707,1,0,0,0,730,718,1,0,0,0,730,727,1,0,0,0,
731,156,1,0,0,0,732,741,5,48,0,0,733,737,7,4,0,0,734,736,7,3,0,0,735,734,
1,0,0,0,736,739,1,0,0,0,737,735,1,0,0,0,737,738,1,0,0,0,738,741,1,0,0,0,
739,737,1,0,0,0,740,732,1,0,0,0,740,733,1,0,0,0,741,158,1,0,0,0,742,744,
7,5,0,0,743,745,7,6,0,0,744,743,1,0,0,0,744,745,1,0,0,0,745,747,1,0,0,0,
746,748,7,3,0,0,747,746,1,0,0,0,748,749,1,0,0,0,749,747,1,0,0,0,749,750,
1,0,0,0,750,160,1,0,0,0,751,752,5,116,0,0,752,753,5,114,0,0,753,754,5,117,
0,0,754,761,5,101,0,0,755,756,5,102,0,0,756,757,5,97,0,0,757,758,5,108,0,
0,758,759,5,115,0,0,759,761,5,101,0,0,760,751,1,0,0,0,760,755,1,0,0,0,761,
162,1,0,0,0,762,763,5,101,0,0,763,764,5,109,0,0,764,765,5,112,0,0,765,766,
5,116,0,0,766,767,5,121,0,0,767,164,1,0,0,0,768,769,5,110,0,0,769,770,5,
117,0,0,770,771,5,109,0,0,771,772,5,98,0,0,772,773,5,101,0,0,773,774,5,114,
0,0,774,166,1,0,0,0,775,776,5,98,0,0,776,777,5,111,0,0,777,778,5,111,0,0,
778,779,5,108,0,0,779,780,5,101,0,0,780,781,5,97,0,0,781,782,5,110,0,0,782,
168,1,0,0,0,783,784,5,115,0,0,784,785,5,116,0,0,785,786,5,114,0,0,786,787,
5,105,0,0,787,788,5,110,0,0,788,789,5,103,0,0,789,170,1,0,0,0,790,791,5,
117,0,0,791,792,5,110,0,0,792,793,5,107,0,0,793,794,5,110,0,0,794,795,5,
111,0,0,795,796,5,119,0,0,796,797,5,110,0,0,797,172,1,0,0,0,798,799,5,110,
0,0,799,800,5,117,0,0,800,801,5,108,0,0,801,802,5,108,0,0,802,174,1,0,0,
0,803,804,5,93,0,0,804,176,1,0,0,0,805,806,5,91,0,0,806,178,1,0,0,0,807,
808,5,62,0,0,808,180,1,0,0,0,809,810,5,60,0,0,810,182,1,0,0,0,811,812,5,
41,0,0,812,184,1,0,0,0,813,814,5,40,0,0,814,186,1,0,0,0,815,816,5,125,0,
0,816,188,1,0,0,0,817,818,5,123,0,0,818,190,1,0,0,0,819,820,5,34,0,0,820,
192,1,0,0,0,821,822,5,96,0,0,822,194,1,0,0,0,823,824,5,58,0,0,824,196,1,
0,0,0,825,826,5,59,0,0,826,198,1,0,0,0,827,828,5,44,0,0,828,200,1,0,0,0,
829,830,5,63,0,0,830,202,1,0,0,0,831,832,5,46,0,0,832,204,1,0,0,0,833,834,
5,61,0,0,834,206,1,0,0,0,835,836,5,43,0,0,836,837,5,61,0,0,837,208,1,0,0,
0,838,839,5,45,0,0,839,840,5,61,0,0,840,210,1,0,0,0,841,842,5,61,0,0,842,
843,5,61,0,0,843,212,1,0,0,0,844,845,5,33,0,0,845,846,5,61,0,0,846,214,1,
0,0,0,847,848,5,62,0,0,848,849,5,61,0,0,849,216,1,0,0,0,850,851,5,60,0,0,
851,852,5,61,0,0,852,218,1,0,0,0,853,854,5,124,0,0,854,858,5,124,0,0,855,
856,5,111,0,0,856,858,5,114,0,0,857,853,1,0,0,0,857,855,1,0,0,0,858,220,
1,0,0,0,859,860,5,124,0,0,860,222,1,0,0,0,861,862,5,38,0,0,862,867,5,38,
0,0,863,864,5,97,0,0,864,865,5,110,0,0,865,867,5,100,0,0,866,861,1,0,0,0,
866,863,1,0,0,0,867,224,1,0,0,0,868,871,5,33,0,0,869,871,3,97,48,0,870,868,
1,0,0,0,870,869,1,0,0,0,871,226,1,0,0,0,872,874,5,64,0,0,873,872,1,0,0,0,
873,874,1,0,0,0,874,875,1,0,0,0,875,879,3,231,115,0,876,878,3,233,116,0,
877,876,1,0,0,0,878,881,1,0,0,0,879,877,1,0,0,0,879,880,1,0,0,0,880,894,
1,0,0,0,881,879,1,0,0,0,882,884,5,64,0,0,883,882,1,0,0,0,883,884,1,0,0,0,
884,885,1,0,0,0,885,886,3,229,114,0,886,890,3,231,115,0,887,889,3,233,116,
0,888,887,1,0,0,0,889,892,1,0,0,0,890,888,1,0,0,0,890,891,1,0,0,0,891,894,
1,0,0,0,892,890,1,0,0,0,893,873,1,0,0,0,893,883,1,0,0,0,894,228,1,0,0,0,
895,896,5,95,0,0,896,230,1,0,0,0,897,898,7,7,0,0,898,232,1,0,0,0,899,902,
3,231,115,0,900,902,3,229,114,0,901,899,1,0,0,0,901,900,1,0,0,0,902,234,
1,0,0,0,903,904,5,47,0,0,904,905,5,47,0,0,905,909,1,0,0,0,906,908,8,8,0,
0,907,906,1,0,0,0,908,911,1,0,0,0,909,907,1,0,0,0,909,910,1,0,0,0,910,912,
1,0,0,0,911,909,1,0,0,0,912,913,6,117,0,0,913,236,1,0,0,0,914,915,5,47,0,
0,915,916,5,42,0,0,916,917,5,42,0,0,917,921,1,0,0,0,918,920,9,0,0,0,919,
918,1,0,0,0,920,923,1,0,0,0,921,922,1,0,0,0,921,919,1,0,0,0,922,924,1,0,
0,0,923,921,1,0,0,0,924,925,5,42,0,0,925,926,5,47,0,0,926,927,1,0,0,0,927,
928,6,118,1,0,928,238,1,0,0,0,929,930,5,47,0,0,930,931,5,42,0,0,931,935,
1,0,0,0,932,934,9,0,0,0,933,932,1,0,0,0,934,937,1,0,0,0,935,936,1,0,0,0,
935,933,1,0,0,0,936,938,1,0,0,0,937,935,1,0,0,0,938,939,5,42,0,0,939,940,
5,47,0,0,940,941,1,0,0,0,941,942,6,119,0,0,942,240,1,0,0,0,943,945,7,9,0,
0,944,943,1,0,0,0,945,946,1,0,0,0,946,944,1,0,0,0,946,947,1,0,0,0,947,948,
1,0,0,0,948,949,6,120,2,0,949,242,1,0,0,0,31,0,254,263,268,272,276,698,703,
712,716,722,725,730,737,740,744,749,760,857,866,870,873,879,883,890,893,
901,909,921,935,946,3,0,1,0,0,2,0,6,0,0];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class graphLexer extends antlr4.Lexer {

    static grammarFileName = "graph.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE" ];
	static literalNames = [ null, "'from'", null, "'+'", "'-'", "'*'", "'/'", 
                         "'%'", "'apply'", "'with'", "'as'", "'async'", 
                         "'block'", "'blockcall'", "'catch'", "'comment'", 
                         "'conditions'", "'confident'", "'context'", "'cow'", 
                         "'default'", "'defined'", "'digression'", "'digressions'", 
                         "'disable'", "'do'", "'done'", "'else'", "'enable'", 
                         "'external'", "'exit'", "'finally'", "'for'", "'function'", 
                         "'global'", "'goto'", "'if'", "'import'", "'in'", 
                         "'input'", "'is'", "'library'", "'logexpr'", "'node'", 
                         "'not'", "'of'", "'on'", "'ondigreturn'", "'onexit'", 
                         "'output'", "'preprocessor'", "'priority'", "'return'", 
                         "'save'", "'set'", "'shared'", "'start'", "'static'", 
                         "'sync'", "'system'", "'timeout'", "'transitions'", 
                         "'tags'", "'try'", "'type'", "'var'", "'wait'", 
                         "'when'", "'fill'", "'#'", "'$'", null, null, null, 
                         "'empty'", "'number'", "'boolean'", "'string'", 
                         "'unknown'", "'null'", "']'", "'['", "'>'", "'<'", 
                         "')'", "'('", "'}'", "'{'", "'\"'", "'`'", "':'", 
                         "';'", "','", "'?'", "'.'", "'='", "'+='", "'-='", 
                         "'=='", "'!='", "'>='", "'<='", null, "'|'" ];
	static symbolicNames = [ null, null, "StringLiteral", "PLUS", "MINUS", 
                          "STAR", "SLASH", "PERCENT", "APPLY", "WITH", "AS", 
                          "ASYNC", "BLOCK", "BLOCKCALL", "CATCH", "COMMENT", 
                          "CONDITIONS", "CONFIDENT", "CONTEXT", "COW", "DEFAULT", 
                          "DEFINED", "DIGRESSION", "DIGRESSIONS", "DISABLE", 
                          "DO", "DONE", "ELSE", "ENABLE", "EXTERNAL", "EXIT", 
                          "FINALLY", "FOR", "FUNCTION", "GLOBAL", "GOTO", 
                          "IF", "IMPORT", "IN", "INPUT", "IS", "LIBRARY", 
                          "LOGEXPR", "NODE", "NOT", "OF", "ON", "ONDIGRETURN", 
                          "ONEXIT", "OUTPUT", "PREPROCESSOR", "PRIORITY", 
                          "RETURN", "SAVE", "SET", "SHARED", "START", "STATIC", 
                          "SYNC", "SYSTEM", "TIMEOUT", "TRANSITIONS", "TAGS", 
                          "TRY", "TYPE", "VAR", "WAIT", "WHEN", "FILL", 
                          "BuiltinMark", "ContextMark", "DecimalLiteral", 
                          "DecimalIntegerLiteral", "BooleanLiteral", "Empty", 
                          "Number", "Boolean", "String", "Unknown", "NULL", 
                          "RBRACKET", "LBRACKET", "RANGLE", "LANGLE", "RPAREN", 
                          "LPAREN", "RCURL", "LCURL", "QUOTE", "BACKTRICK", 
                          "COLON", "SEMICOLON", "COMMA", "QMARK", "PERIOD", 
                          "EQUALSIGN", "AddOp", "RemoveOp", "EqualOp", "NotEqualOp", 
                          "GreaterEqualOp", "LessEqualOp", "OrOp", "TypeOr", 
                          "AndOp", "NotOperator", "ID", "LineComment", "DocsComment", 
                          "BlockComment", "Whitespace" ];
	static ruleNames = [ "T__0", "SafeCodePoint", "StringLiteral", "StringCharacter", 
                      "MultilineStringCharacter", "MultilineEscapeSequence", 
                      "EscapeSequence", "PLUS", "MINUS", "STAR", "SLASH", 
                      "PERCENT", "APPLY", "WITH", "AS", "ASYNC", "BLOCK", 
                      "BLOCKCALL", "CATCH", "COMMENT", "CONDITIONS", "CONFIDENT", 
                      "CONTEXT", "COW", "DEFAULT", "DEFINED", "DIGRESSION", 
                      "DIGRESSIONS", "DISABLE", "DO", "DONE", "ELSE", "ENABLE", 
                      "EXTERNAL", "EXIT", "FINALLY", "FOR", "FUNCTION", 
                      "GLOBAL", "GOTO", "IF", "IMPORT", "IN", "INPUT", "IS", 
                      "LIBRARY", "LOGEXPR", "NODE", "NOT", "OF", "ON", "ONDIGRETURN", 
                      "ONEXIT", "OUTPUT", "PREPROCESSOR", "PRIORITY", "RETURN", 
                      "SAVE", "SET", "SHARED", "START", "STATIC", "SYNC", 
                      "SYSTEM", "TIMEOUT", "TRANSITIONS", "TAGS", "TRY", 
                      "TYPE", "VAR", "WAIT", "WHEN", "FILL", "BuiltinMark", 
                      "ContextMark", "DecimalLiteral", "DecimalIntegerLiteral", 
                      "DecimalLiteralAbsoluteValue", "DecimalIntegerLiteralAbsoluteValue", 
                      "ExponentPart", "BooleanLiteral", "Empty", "Number", 
                      "Boolean", "String", "Unknown", "NULL", "RBRACKET", 
                      "LBRACKET", "RANGLE", "LANGLE", "RPAREN", "LPAREN", 
                      "RCURL", "LCURL", "QUOTE", "BACKTRICK", "COLON", "SEMICOLON", 
                      "COMMA", "QMARK", "PERIOD", "EQUALSIGN", "AddOp", 
                      "RemoveOp", "EqualOp", "NotEqualOp", "GreaterEqualOp", 
                      "LessEqualOp", "OrOp", "TypeOr", "AndOp", "NotOperator", 
                      "ID", "Underscore", "NonUnderscoreIdSymbol", "AnyIdSymbol", 
                      "LineComment", "DocsComment", "BlockComment", "Whitespace" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.atn.PredictionContextCache());
    }
}

graphLexer.EOF = antlr4.Token.EOF;
graphLexer.T__0 = 1;
graphLexer.StringLiteral = 2;
graphLexer.PLUS = 3;
graphLexer.MINUS = 4;
graphLexer.STAR = 5;
graphLexer.SLASH = 6;
graphLexer.PERCENT = 7;
graphLexer.APPLY = 8;
graphLexer.WITH = 9;
graphLexer.AS = 10;
graphLexer.ASYNC = 11;
graphLexer.BLOCK = 12;
graphLexer.BLOCKCALL = 13;
graphLexer.CATCH = 14;
graphLexer.COMMENT = 15;
graphLexer.CONDITIONS = 16;
graphLexer.CONFIDENT = 17;
graphLexer.CONTEXT = 18;
graphLexer.COW = 19;
graphLexer.DEFAULT = 20;
graphLexer.DEFINED = 21;
graphLexer.DIGRESSION = 22;
graphLexer.DIGRESSIONS = 23;
graphLexer.DISABLE = 24;
graphLexer.DO = 25;
graphLexer.DONE = 26;
graphLexer.ELSE = 27;
graphLexer.ENABLE = 28;
graphLexer.EXTERNAL = 29;
graphLexer.EXIT = 30;
graphLexer.FINALLY = 31;
graphLexer.FOR = 32;
graphLexer.FUNCTION = 33;
graphLexer.GLOBAL = 34;
graphLexer.GOTO = 35;
graphLexer.IF = 36;
graphLexer.IMPORT = 37;
graphLexer.IN = 38;
graphLexer.INPUT = 39;
graphLexer.IS = 40;
graphLexer.LIBRARY = 41;
graphLexer.LOGEXPR = 42;
graphLexer.NODE = 43;
graphLexer.NOT = 44;
graphLexer.OF = 45;
graphLexer.ON = 46;
graphLexer.ONDIGRETURN = 47;
graphLexer.ONEXIT = 48;
graphLexer.OUTPUT = 49;
graphLexer.PREPROCESSOR = 50;
graphLexer.PRIORITY = 51;
graphLexer.RETURN = 52;
graphLexer.SAVE = 53;
graphLexer.SET = 54;
graphLexer.SHARED = 55;
graphLexer.START = 56;
graphLexer.STATIC = 57;
graphLexer.SYNC = 58;
graphLexer.SYSTEM = 59;
graphLexer.TIMEOUT = 60;
graphLexer.TRANSITIONS = 61;
graphLexer.TAGS = 62;
graphLexer.TRY = 63;
graphLexer.TYPE = 64;
graphLexer.VAR = 65;
graphLexer.WAIT = 66;
graphLexer.WHEN = 67;
graphLexer.FILL = 68;
graphLexer.BuiltinMark = 69;
graphLexer.ContextMark = 70;
graphLexer.DecimalLiteral = 71;
graphLexer.DecimalIntegerLiteral = 72;
graphLexer.BooleanLiteral = 73;
graphLexer.Empty = 74;
graphLexer.Number = 75;
graphLexer.Boolean = 76;
graphLexer.String = 77;
graphLexer.Unknown = 78;
graphLexer.NULL = 79;
graphLexer.RBRACKET = 80;
graphLexer.LBRACKET = 81;
graphLexer.RANGLE = 82;
graphLexer.LANGLE = 83;
graphLexer.RPAREN = 84;
graphLexer.LPAREN = 85;
graphLexer.RCURL = 86;
graphLexer.LCURL = 87;
graphLexer.QUOTE = 88;
graphLexer.BACKTRICK = 89;
graphLexer.COLON = 90;
graphLexer.SEMICOLON = 91;
graphLexer.COMMA = 92;
graphLexer.QMARK = 93;
graphLexer.PERIOD = 94;
graphLexer.EQUALSIGN = 95;
graphLexer.AddOp = 96;
graphLexer.RemoveOp = 97;
graphLexer.EqualOp = 98;
graphLexer.NotEqualOp = 99;
graphLexer.GreaterEqualOp = 100;
graphLexer.LessEqualOp = 101;
graphLexer.OrOp = 102;
graphLexer.TypeOr = 103;
graphLexer.AndOp = 104;
graphLexer.NotOperator = 105;
graphLexer.ID = 106;
graphLexer.LineComment = 107;
graphLexer.DocsComment = 108;
graphLexer.BlockComment = 109;
graphLexer.Whitespace = 110;



